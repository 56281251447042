<template>
  <div class="navigation">
    <div class="title">
      <span> 机构管理/ </span> <span class="black">机构维护</span>
    </div>
    <el-card class="condition_style2">
      <div class="condition_div">
        <el-form :inline="true" class="flex">
          <el-form-item>
            <el-button type="primary" size="small" @click="add('')">新增机构</el-button>
          </el-form-item>
          <div>
            <el-form-item>
              <el-input v-model="searchForm.mchName" placeholder="请输入机构名称" suffix-icon="el-icon-search" clearable>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-select placeholder="请选择状态" filterable v-model="searchForm.status" clearable>
                <el-option v-for="(item, index) in typeList" :key="index" :label="item.type" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
        <el-table-column type="index" label="序号" width="60" align="center" fixed>
          <template slot-scope="scope">{{scope.$index+(current-1) * size + 1}}</template>
        </el-table-column>
        <el-table-column prop="mchName" label="商户名称" min-width="120" align="center"></el-table-column>
        <el-table-column prop="mchKey" label="身份识别(key)" min-width="180" align="center"></el-table-column>
        <el-table-column prop="artificial" label="负责人" min-width="180" align="center"></el-table-column>
        <el-table-column prop="phone" label="联系电话" min-width="320" align="center">
          <template slot-scope="scope"> {{scope.row.phone }}</template>
        </el-table-column>
        <el-table-column prop="address" label="详细地址" min-width="250" align="center">
          <template slot-scope="scope">
            {{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.districtName}}{{scope.row.address}}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="当前状态" min-width="80" align="center">
          <template slot-scope="scope"> {{scope.row.status == 1?"禁用":"启用" }}</template>
        </el-table-column>
        <el-table-column prop="updateTime" label="最后操作时间" min-width="200" align="center"></el-table-column>
        <el-table-column prop="updateBy" label="最后操作人" min-width="100" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button class="ge" type="text" size="small" @click="changeState(scope.row,1)">
              {{scope.row.status==1?"启用":"禁用"}}</el-button><span class="ge">|</span>
            <el-button type="text" size="small" @click="add(scope.row)" class="ge"> 编辑 </el-button>
            <span class="ge">|</span>
            <el-button type="text" size="small" @click="maintainSchool(scope.row.mchId)">学校管理 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :page="current" :size="size" :total="total" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"></pagination>
    </el-card>
    <!-- 新增\编辑 -->
    <modal :obj="{
        title: edit ? '编辑机构' : '新增机构',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
      }" @submit="addSubmit">
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-form-item label="登录账号：" prop="mchAccount">
          <el-input v-model="addForm.mchAccount" placeholder="请输入登录账号"></el-input>
        </el-form-item>
        <el-form-item label="商户名称：" prop="mchName">
          <el-input v-model="addForm.mchName" placeholder="请输入商户名称"></el-input>
        </el-form-item>
        <el-form-item label="授权秘钥：" prop="mchKey">
          <el-input v-model="addForm.mchKey" placeholder="请输入授权秘钥"></el-input>
        </el-form-item>
        <el-form-item label="所属市区：" prop="area">
          <el-cascader class="w_100" v-model="addForm.area" :options="areaLevelList"
            :props="{ value: 'id', label: 'name'  }" ref="clearCheckedNodes"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址：" prop="address">
          <el-input v-model="addForm.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="联系人：" prop="artificial">
          <el-input v-model="addForm.artificial" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="phone">
          <el-input v-model="addForm.phone" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="社会征信号：" prop="uscc">
          <el-input v-model="addForm.uscc" placeholder="请输入社会征信号"></el-input>
        </el-form-item>
        <el-form-item label="账号有效期：" prop="expiredDate">
          <el-date-picker v-model="addForm.expiredDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
            placeholder="请选择账号有效期至" class="w_100">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="拥有权限：" prop="arr">
          <el-checkbox-group v-model="addForm.arr" @change="changePeriss" @input="$forceUpdate()">
            <el-checkbox :label="item.id" v-for="(item, index) in permissList" :key="index">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import pagination from "@/components/pagination";
  import modal from "@/components/modal";
  import {
    areaLevel,
    getMerchantList,
    merchantSave,
    merchantUpdate,
    merchantStatus,
    merchantInfo
  } from "@/api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        size: 10, //每页条数
        current: 1, //当前条数
        total: 0,
        searchForm: {
          mchName: "",
          status: ""
        },
        tableHeight: this.$util.getHeight(310),
        tableData: [],
        typeList: [{
          id: null,
          type: "全部"
        }, {
          id: 0,
          type: "启用"
        }, {
          id: 1,
          type: "禁用"
        }],
        areaLevelList: [],
        addDialog: false,
        edit: "",
        addForm: {
          mchName: "",
          mchAccount: "",
          mchKey: "",
          artificial: "",
          area: [],
          uscc: "",
          status: "",
          province: "",
          city: "",
          district: "",
          address: "",
          phone: "",
          districtPermission: "",
          provincePermission: "",
          cityPermission: "",
          remark: "",
          arr: []

        },
        permissList: [{
          id: "0",
          name: "是否拥有创建省工作台权限",
        }, {
          id: "1",
          name: "是否拥有创建市工作台权限",
        }, {
          id: "2",
          name: "是否拥有创建区工作台权限",
        }],
        rules: {
          mchName: [{
            required: true,
            message: "请输入商户名称"
          }],
          mchKey: [{
            required: true,
            message: "请选择授权密钥"
          }],
          area: [{
            required: true,
            message: "请选择所属区域"
          }],
          arr: [{
            required: true,
            message: "请勾选权限"
          }],
          mchAccount: [{
            required: true,
            message: "请输入商户账号"
          }],
          artificial: [{
            required: true,
            message: "请输入联系人"
          }],
          expiredDate: [{
            required: true,
            message: "选择账号有效时间"
          }],
          phone: [{
            required: true,
            message: "请输入联系电话"
          }],
        },
      };
    },
    computed: {
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },
    },
    created() {
      this.getList()
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.getTableHeight(330)
      })
      // this.getAreaFourLevel()
    },
    methods: {
      getTableHeight(val) {
        this.tableHeight = this.$util.getHeight(val)
      },

      getList(val) {
        if (val) {
          this.current = 1;
        }
        this.$request(getMerchantList(this.current, this.size), this.searchForm).then((res) => {
          if (res.code === 0) {
            let data = res.data;
            this.tableData = data.records;
            this.total = Number(data.total);
          }
        });
      },
      // 获取地区
      getAreaFourLevel() {
        this.$request(areaLevel).then((res) => {
          if (res.code === 0) {
            let list = res.data
            this.areaLevelList = list
          }
        });
      },
      changePeriss(e) {
        this.$forceUpdate()
        this.addForm.arr = e
      },
      //启用禁用 
      changeState(val) {
        let text = val.status == 1 ? "确认启用?" : "确认禁用?";
        this.$util.isSwal(text, () => {
          this.$request(merchantStatus, {
            mchId: val.mchId,
            status: val.status == "0" ? "1" : "0"
          }).then((res) => {
            if (res.code == 0) {
              this.$util.message("suc", "操作成功");
              this.getList();
            }
          });
        });
      },
      //   新增\编辑-弹框
      add(val) {
        this.edit = val;
        this.addDialog = true;
        this.addForm = {
          mchName: "",
          mchAccount: "",
          mchKey: "",
          artificial: "",
          area: [],
          uscc: "",
          status: "",
          province: "",
          city: "",
          district: "",
          address: "",
          phone: "",
          districtPermission: 1,
          provincePermission: 1,
          cityPermission: 1,
          remark: "",
          arr: []
        }
        if (this.$refs.clearCheckedNodes) {
          this.$refs.clearCheckedNodes.$refs.panel.clearCheckedNodes();
          this.$refs.clearCheckedNodes.$refs.panel.activePath = [];
        }
        if (this.$refs.addForm) {
          this.$refs.addForm.resetFields();
        }
        if (!this.areaLevelList.length) {
          this.getAreaFourLevel()
        }
        if (this.edit) {
          this.getInfo(val.mchId);
        }
      },
      // 详情
      getInfo(id) {
        this.$request(merchantInfo, {
          mchId: id
        }).then((res) => {
          if (res.code == 0) {
            this.addForm = res.data
            this.addForm.area = (this.addForm.province + "," + this.addForm.city + "," + this.addForm.district)
              .split(',')
            this.addForm.arr = (this.addForm.provincePermission + "," + this.addForm.cityPermission + "," + this
              .addForm.districtPermission).split(',')
          }
        });
      },
      //   新增编辑-上传接口
      addSubmit() {
        let url = this.edit ? merchantUpdate : merchantSave;
        this.$refs.addForm.validate((result) => {
          if (this.addForm.arr && this.addForm.arr.length) {
            this.addForm.arr.forEach((item) => {
              if (item == 0) {
                this.addForm.provincePermission = 0
              } else if (item == 1) {
                this.addForm.cityPermission = 0
              } else {
                this.addForm.districtPermission = 0
              }
            })
          }
          this.addForm.province = this.addForm.area[0]
          this.addForm.city = this.addForm.area[1]
          this.addForm.district = this.addForm.area[2]
          if (result) {
            this.$request(url, this.addForm).then((res) => {
              if (res.code == 0) {
                if (res.data) {
                  this.addDialog = false;
                  this.$util.message("suc", "操作成功");
                  this.getList();
                }
              }
            });
          }
        });
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getList();
      },
      maintainSchool(mchId) {
        this.$router.push({
          name: "maintainSchool",
          params: {
            id: mchId,
            form: JSON.stringify(this.searchForm)
          }
        });
      }
    },
  };
</script>

<style lang="less" scoped>
  .navigation {
    /deep/.el-checkbox {
      display: block;
    }
  }
</style>